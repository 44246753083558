<script>
import { camelCase, get } from 'lodash-es';

import {
  BE_INACTIVE_STATUS,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_PLAN_COLOR_MAP,
  SUBSCRIPTION_PLAN_ICONS,
  SUBSCRIPTION_PLAN_ICON_COLORS,
  SUBSCRIPTION_SHOW_DAYS_LIMIT,
} from '~/support/constants';
import { subscription } from '~/support/utils';

export default {
  name: 'SubscriptionWrapper',

  props: {
    subscription: {
      required: true,
      type: Object,
    },
  },

  computed: {
    cardPalette() {
      return get(
        SUBSCRIPTION_PLAN_COLOR_MAP,
        this.subscriptionPlan,
        SUBSCRIPTION_PLAN_COLOR_MAP[SUBSCRIPTION_PLANS.free],
      );
    },

    daysLeft() {
      return subscription.daysLeft(this.subscription.endDate);
    },

    daysLeftText() {
      if (this.isPlanExpired) {
        return this.$t('expired');
      }

      if (this.daysLeft === 0) {
        return this.$t('expiresToday');
      }

      return `${this.daysLeft} ${this.$t('daysLeft')}`;
    },

    endDayText() {
      return this.$formatting.localizedDate(this.$auth.user.subscription.endDate);
    },

    enrolledOn() {
      return `${this.$t('enrolledOn')} ${this.$formatting.localizedDate(this.subscription.startDate)}.`;
    },

    enrolledOnDate() {
      return this.$formatting.localizedDate(this.subscription.startDate);
    },

    expiresOn() {
      if (!this.subscription?.endDate) {
        return '';
      }

      return `${this.$t('expiresOn')} ${this.$formatting.localizedDate(this.subscription.endDate)}.`;
    },

    expiresOnDate() {
      if (!this.subscription.endDate) {
        return '';
      }

      return this.$formatting.localizedDate(this.subscription.endDate);
    },

    hasEndDate() {
      return !!this.subscription.endDate;
    },

    iconColor() {
      return SUBSCRIPTION_PLAN_ICON_COLORS[camelCase(this.subscriptionPlan)];
    },

    iconName() {
      return SUBSCRIPTION_PLAN_ICONS[camelCase(this.subscriptionPlan)];
    },

    isPlanExpired() {
      return this.subscription.status === BE_INACTIVE_STATUS;
    },

    shouldShowDaysLeft() {
      if (!this.hasEndDate) return;

      return this.daysLeft < SUBSCRIPTION_SHOW_DAYS_LIMIT;
    },

    startDayText() {
      return this.$formatting.localizedDate(this.$auth.user.subscription.startDate);
    },

    subscriptionPlan() {
      return this.subscription.plan;
    },
  },

  render() {
    return this.$slots.default({
      daysLeftText: this.daysLeftText,
      cardPalette: this.cardPalette,
      enrolledOn: this.enrolledOn,
      enrolledOnDate: this.enrolledOnDate,
      expiresOn: this.expiresOn,
      expiresOnDate: this.expiresOnDate,
      iconColor: this.iconColor,
      iconName: this.iconName,
      shouldShowDaysLeft: this.shouldShowDaysLeft,
      isPlanExpired: this.isPlanExpired,
      subscriptionPlan: this.subscriptionPlan,
      startDayText: this.startDayText,
      endDayText: this.endDayText,
      hasEndDate: this.hasEndDate,
    });
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "daysLeft": "days left",
    "enrolledOn": "Enrolled on:",
    "expired": "Expired",
    "expiresOn": "Expires on:",
    "expiresToday": "Expires Today"
  }
}
</i18n>
